import styled from "@emotion/styled";
import { PageWrapper } from "components/common/PageWrapper";
import { ReactNode } from "react";
import { FormattedMessage, translationKeys } from "services";
import { BackNav } from "../../ui/BackNav/BackNav";

const backNavOffset = 116;
const sizeOfBackNav = 32;
const BackNavContainer = styled.div`
  position: absolute;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    position: inherit;
    margin-left: -${backNavOffset}px;
    padding-right: ${backNavOffset - sizeOfBackNav}px;
  }
`;

const ColumnContent = styled.article`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const Container = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 100px;
  }
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    padding: 0 200px;
  }
  color: ${({ theme }) => `${theme.palette.primary.main}`};
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.375rem;
  margin-bottom: 36px;
`;

const Header = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
  text-align: center;
`;

const HeaderDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
`;

type Props = {
  children?: ReactNode;
  titleLocalizationId: translationKeys;
  descriptionLocalizationId: translationKeys;
  backNavHref?: string;
};
export const SingleColumnLayout: React.FC<Props> = (props) => {
  return (
    <PageWrapper>
      <Container>
        <BackNavContainer>
          <BackNav href={props.backNavHref} />
        </BackNavContainer>
        <ColumnContent>
          <HeaderContainer>
            <Header>
              <FormattedMessage id={props.titleLocalizationId} />
            </Header>
            <HeaderDescription>
              <FormattedMessage id={props.descriptionLocalizationId} />
            </HeaderDescription>
          </HeaderContainer>
          {props.children}
        </ColumnContent>
      </Container>
    </PageWrapper>
  );
};
