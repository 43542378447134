import { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import OTType from "@opentok/client";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { IconButton, Stack, Alert, Box } from "@mui/material";
import { Modal } from "components/common/ui/Modal";
import { debug, isFeatureEnabled } from "tools";
import { Button } from "components/common/ui/Button";

interface SurveyVideoCredentials {
  apiKey: string;
  sessionId: string;
  token: string;
}

const Wrapper = styled.div`
  background: #000;
  height: 100%;
  width: 100%;
`;

const AgentStatus = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px;
`;

const PermissionMessage = styled.div`
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  height: 100%;
  text-align: center;
  padding: 30px;
`;

const VideosContainer = styled.div<{ visible: boolean }>`
  position: relative;
  height: 100%;
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const VideoContainer = styled.div<{
  isMinimized: boolean;
  showPip: boolean;
}>`
  transition: all 0.3s;
  position: relative;
  width: 100%;
  height: 100%;
  display: ${({ isMinimized, showPip }) =>
    isMinimized && !showPip ? "none" : "block"};

  ${({ isMinimized }) =>
    isMinimized
      ? css`
          position: absolute;
          width: 120px;
          height: 120px;
          top: 10px;
          right: 10px;
          z-index: 1;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
          .OT_fit-mode-contain .OT_video-element {
            object-fit: cover;
          }
        `
      : null}
`;

const VideoControlButton = styled(IconButton)`
  display: flex;
  border-radius: 50%;
  background: white;

  &:hover {
    background-color: white;
    transform: scale(1.1);
  }
`;

type PermissionStatus = "requesting" | "denied" | "granted";

const useSurveyVideo = ({
  apiKey,
  sessionId,
  token,
}: SurveyVideoCredentials) => {
  const initiated = useRef(false);
  const publisherRef = useRef<HTMLDivElement | null>(null);
  const subscriberRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [publisher, setPublisher] = useState<OTType.Publisher | null>(null);
  const [subscriber, setSubscriber] = useState<OTType.Subscriber | null>(null);
  const [hasMultipleCameras, setHasMultipleCameras] = useState(false);
  const [permissionStatus, setPermissionStatus] =
    useState<PermissionStatus | null>(null);
  const hasSubscriber = Boolean(subscriber);

  const handleError = (error: OTType.OTError | undefined) => {
    if (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    let session: OTType.Session | undefined = undefined;

    const init = async () => {
      initiated.current = true;
      const OT = (await import("@opentok/client")).default;
      session = OT.initSession(apiKey, sessionId);

      OT.getDevices((_error, devices) => {
        const videoInputs = (devices || []).filter(
          ({ kind }) => kind === "videoInput"
        );
        setHasMultipleCameras(videoInputs.length > 1);
      });

      session.on("streamDestroyed", (_event) => {
        setSubscriber(null);
      });

      session.on("streamCreated", (event) => {
        const sub = session!.subscribe(
          event.stream,
          subscriberRef.current!,
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
            showControls: false,
            fitMode: "contain",
          },
          handleError
        );
        sub.on({
          disconnected: () => {
            setSubscriber(null);
          },
          connected: () => {
            setSubscriber(sub);
          },
          destroyed: () => {
            setSubscriber(null);
          },
        });
      });

      session.on("sessionDisconnected", (event) => {
        debug("You were disconnected from the session.", event.reason);
      });

      session.connect(token, function (error) {
        if (error) {
          handleError(error);
        } else {
          const publisher = OT.initPublisher(
            publisherRef.current!,
            {
              insertMode: "append",
              width: "100%",
              height: "100%",
              showControls: false,
              fitMode: "contain",
            },
            handleError
          );

          publisher.on({
            accessDialogOpened: function (event: any) {
              setPermissionStatus("requesting");
            },
            accessAllowed: function (event: any) {
              setPermissionStatus("granted");
            },
            accessDenied: function (event: any) {
              setPermissionStatus("denied");
            },
          });

          session!.publish(publisher);
          setPublisher(publisher);
        }
      });
    };
    if (!(session || initiated.current)) {
      init();
    }

    return () => {
      if (session) {
        session.disconnect();
      }
    };
  }, [apiKey, sessionId, token]);

  return {
    error,
    publisher,
    subscriber,
    publisherRef,
    subscriberRef,
    hasSubscriber,
    hasMultipleCameras,
    permissionStatus,
  };
};

const videoType = {
  publisher: "publisher",
  subscriber: "subscriber",
};
type VideoType = (typeof videoType)[keyof typeof videoType];

function useSurveyVideoControls(
  publisher: OTType.Publisher | null,
  initialPrimary?: VideoType
) {
  const [showPip, setShowPip] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  const [publishVideo, setPublishVideo] = useState(true);
  const [primaryVideo, setPrimaryVideo] = useState<VideoType>(
    initialPrimary || videoType.publisher
  );

  const togglePip = () => {
    setShowPip((prev) => !prev);
  };

  const toggleAudio = () => {
    publisher?.publishAudio(!publishAudio);
    setPublishAudio((prev) => !prev);
  };

  const toggleVideo = () => {
    publisher?.publishVideo(!publishVideo);
    setPublishVideo((prev) => !prev);
  };

  const toggleVideoSource = () => {
    publisher?.cycleVideo();
  };

  return {
    togglePip,
    toggleAudio,
    toggleVideo,
    toggleVideoSource,
    setPrimaryVideo,
    showPip,
    publishAudio,
    publishVideo,
    primaryVideo,
  };
}

interface VonageVideoProps extends SurveyVideoCredentials {}

export function VonageVideo({ apiKey, sessionId, token }: VonageVideoProps) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const {
    publisher,
    publisherRef,
    subscriberRef,
    hasSubscriber,
    hasMultipleCameras,
    permissionStatus,
  } = useSurveyVideo({ apiKey, sessionId, token });
  const {
    togglePip,
    toggleAudio,
    toggleVideo,
    toggleVideoSource,
    setPrimaryVideo,
    showPip,
    publishAudio,
    publishVideo,
    primaryVideo,
  } = useSurveyVideoControls(
    publisher,
    hasSubscriber ? videoType.subscriber : videoType.publisher
  );

  useEffect(() => {
    if (!hasSubscriber && primaryVideo !== videoType.publisher) {
      setPrimaryVideo(videoType.publisher);
    }
  }, [hasSubscriber, primaryVideo, setPrimaryVideo]);

  return (
    <Wrapper>
      {permissionStatus === "denied" ? (
        <PermissionMessage>
          It looks like you have blocked permission for this app to use your
          camera. Please go into your browser settings and allow permission for
          Home Safe Connect to access your camera and microphone.
        </PermissionMessage>
      ) : null}
      {permissionStatus === "requesting" ? (
        <PermissionMessage>
          Please grant permission for your browser to access your camera and
          microphone to join the call.
        </PermissionMessage>
      ) : null}
      <VideosContainer visible={permissionStatus === "granted"}>
        <VideoContainer
          isMinimized={primaryVideo !== videoType.publisher && hasSubscriber}
          showPip={showPip}
          onClick={() => setPrimaryVideo(videoType.publisher)}
          ref={publisherRef}
        />
        <VideoContainer
          isMinimized={primaryVideo !== videoType.subscriber && hasSubscriber}
          showPip={showPip}
          onClick={() => setPrimaryVideo(videoType.subscriber)}
          ref={subscriberRef}
        />

        <Stack
          direction="row"
          sx={{
            position: "absolute",
            bottom: 0,
            width: 1,
            pb: 1,
            px: 1,
            gap: 2,
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          {hasSubscriber ? (
            <VideoControlButton onClick={togglePip}>
              <PictureInPictureIcon sx={{ opacity: showPip ? 1 : 0.5 }} />
            </VideoControlButton>
          ) : null}

          <VideoControlButton onClick={toggleVideoSource}>
            <FlipCameraIosIcon />
          </VideoControlButton>

          <VideoControlButton onClick={toggleAudio}>
            {publishAudio ? <MicIcon /> : <MicOffIcon />}
          </VideoControlButton>

          <VideoControlButton onClick={toggleVideo}>
            {publishVideo ? <VideocamIcon /> : <VideocamOffIcon />}
          </VideoControlButton>
          {isFeatureEnabled("FF_END_CALL") && (
            <VideoControlButton onClick={() => setOpenConfirmation(true)}>
              <CallEndIcon color="warning" />
            </VideoControlButton>
          )}
        </Stack>
        {hasSubscriber === false ? (
          <AgentStatus>
            <Alert severity="success">
              Video is connected! Please wait for the survey agent to join.
            </Alert>
          </AgentStatus>
        ) : null}
      </VideosContainer>
      <Modal
        title="Are you sure you want to end the call?"
        titleSize="small"
        open={openConfirmation}
      >
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            variant="secondary"
            onClick={() => setOpenConfirmation(false)}
          >
            Cancel
          </Button>
          <Button onClick={window.close}>End Call</Button>
        </Box>
      </Modal>
    </Wrapper>
  );
}
