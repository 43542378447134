import { useIntl } from "services";
import { MarkdownItem } from "types";
import { DetailItem } from "./components";

import styled from "@emotion/styled";

const Wrapper = styled.div``;

type DetailsListProps = {
  details: MarkdownItem[];
};

export function EntitlementsDetailsList({ details }: DetailsListProps) {
  const { formatMessage } = useIntl();
  return (
    <Wrapper aria-label={formatMessage({ id: "ariaLabels.specialItems" })}>
      {details.map((item: MarkdownItem, index) => (
        <DetailItem data={item} key={index} />
      ))}
    </Wrapper>
  );
}
