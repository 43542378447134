import { useIntl } from "services";
import { MarkdownItem } from "types";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Waypoint } from "react-waypoint";
import { SectionWaypoint } from "components/pages/EntitlementsPage/SectionNavContext";

const Wrapper = styled.div`
  display: flex;
  padding-top: 32px;
`;

const IconColumn = styled.div`
  flex: 0 1 64px;
  margin-right: 12px;
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  flex: 1;
  min-width: 0;
  word-break: break-word;

  h2 {
    font-weight: 400;
    font-size: 24px;
    margin: 0;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0;
  }
  ul {
    padding-left: 18px;
  }
  ul,
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;

const Icon = styled.img`
  display: block;
  max-width: 40px;
  max-height: 40px;
`;

type DetailItemProps = {
  data: MarkdownItem;
};

export function DetailItem({ data }: DetailItemProps) {
  const { itemType, label, markup, icon } = data;
  const { formatMessage } = useIntl();
  return (
    <>
      <SectionWaypoint section={itemType} />
      <Wrapper key={itemType} aria-label={label} id={itemType}>
        <IconColumn>
          <IconWrapper>
            <Icon src={icon} title={label} alt={label} />
          </IconWrapper>
        </IconColumn>
        <ContentWrapper>
          <Typography variant="xlBody" component="h2">
            {label}
          </Typography>
          <div
            aria-label={formatMessage({ id: "ariaLabels.itemRules" })}
            dangerouslySetInnerHTML={{ __html: markup }}
          />
        </ContentWrapper>
      </Wrapper>
    </>
  );
}
