import { ServiceSubtype } from "__generated__/types";
import { Order } from "models";

export const useShouldSkipSurveyInOnboarding = (order: Order) => {
  // Returns true if we should skip the survey schedule page else false
  return (
    order.scheduledSurveyService?.subtype &&
    [ServiceSubtype.Virtual, ServiceSubtype.Onsite].includes(
      order.scheduledSurveyService?.subtype
    )
  );
};
