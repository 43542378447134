import { Field } from "formik";
import { FormattedMessage, useIntl } from "services";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const FieldLabel = styled.label`
  height: 75px;
  display: block;
  margin: 1rem 0;
  width: 100%;
  max-width: 490px;
  padding: 0 16px;
  border-radius: 38px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    input {
      border-color: #aaa;
      border-width: 2px;
    }
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #cccccc;
    cursor: pointer;
    margin: 0px 12px 0px 0px;
  }

  input:checked {
    border: 2px solid #76e4af;
  }

  input:before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #76e4af;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    transform: scale(0);
  }

  input:checked:before {
    transform: scale(1);
  }
`;

type BooleanRadioFields = {
  name?: string;
};

export function BooleanRadioFields({ name = "boolean" }: BooleanRadioFields) {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div>
        <FieldLabel
          aria-label={formatMessage({
            id: "ariaLabels.booleanRadioFields.yes",
          })}
        >
          <Field
            type={"radio"}
            name={name}
            value={"1"}
            data-testid="booleanRadioFields.yes"
          />
          <Typography variant="mBody">
            <FormattedMessage id="actions.yes" />
          </Typography>
        </FieldLabel>
      </div>
      <div>
        <FieldLabel
          aria-label={formatMessage({
            id: "ariaLabels.booleanRadioFields.no",
          })}
        >
          <Field
            type={"radio"}
            name={name}
            value={"0"}
            data-testid="booleanRadioFields.no"
          />
          <Typography variant="mBody">
            <FormattedMessage id="actions.no" />
          </Typography>
        </FieldLabel>
      </div>
    </div>
  );
}
