import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { useOrderLoader } from "services/orders/OrderLoaderContext";
import { getEnv } from "tools";
import { datadogRum } from "@datadog/browser-rum";

const CHAT_HOST = getEnv("NEXT_PUBLIC_CHAT_HOST");
const CHAT_BUS_NO = Number(getEnv("NEXT_PUBLIC_CHAT_BUS_NO"));
const CHAT_POC = getEnv("NEXT_PUBLIC_CHAT_POC");
const CRM_URL = getEnv("NEXT_PUBLIC_CRM_URL");
/**
 * Promise that resolves once the 3rd party chat script has been run
 * @returns Promise
 */
const waitForChatScript = () =>
  new Promise<void>((res) => {
    if (window.icPatronChat !== undefined) {
      res();
    } else {
      let interval: NodeJS.Timer | null = setInterval(() => {
        if (interval && window.icPatronChat !== undefined) {
          clearInterval(interval);
          interval = null;
          res();
        }
      }, 1000);
    }
  });

// Global var to make sure we don't initialize chat more than once
let chatInitialized = false;

/**
 * Renders a chat widget in the lower right of the screen
 * @returns
 */
export const ChatBox: React.FC = () => {
  const { order, status } = useOrderLoader();
  const pathname = usePathname();
  const orderId = order?.id;
  const assignedUserId = order?.assignedUser?.id;
  const customerId = order?.customer?.id;
  const customerFirstName = order?.customer?.firstName;
  const customerLastName = order?.customer?.lastName;
  const customerRank = order?.entitlement?.rank;
  const crmOrderLink = `${CRM_URL}/index.php?module=Orders&record=${orderId}&view=Detail`;

  useEffect(() => {
    if (
      !orderId ||
      !pathname ||
      pathname.includes("survey-video") ||
      pathname.includes("activate")
    ) {
      return;
    }

    // Don't initialize chat until order loader has run through flow
    switch (status) {
      case "loadingNoSaved":
      case "loadingSaved":
      case "unloadedNoSaved":
      case "unloadedSaved":
        return;
    }

    if (chatInitialized) {
      return;
    }

    const params = order
      ? [
          orderId,
          assignedUserId,
          customerId,
          customerFirstName,
          customerLastName,
          customerRank,
          crmOrderLink,
        ]
      : [
          "Unknown",
          "Unknown",
          "Unknown",
          "Unknown",
          "Unknown",
          "Unknown",
          "Unknown",
        ];

    waitForChatScript().then(() => {
      if (chatInitialized) {
        return;
      }

      const initConfig = {
        serverHost: CHAT_HOST,
        bus_no: CHAT_BUS_NO,
        poc: CHAT_POC,
        params: params,
      };

      window.icPatronChat.init(initConfig);
      chatInitialized = true;
      // Log the initialization configuration to help assist
      // with an issue picked up by NICE where the order id parameter
      // appeared to be malformed
      datadogRum.addAction("icPatronChat.init", initConfig);
    });

    return () => {
      // This is a little gross but we need a way to clean up the chat
      // which the out-of-box script does not give us
      const chatEl = document.querySelectorAll(
        ".ie-div-position-customer-chat"
      );
      chatEl.forEach((el) => el.remove());
      chatInitialized = false;
    };
  }, [
    status,
    orderId,
    assignedUserId,
    customerId,
    customerFirstName,
    customerLastName,
    customerRank,
    crmOrderLink,
  ]);

  return null;
};
