import { useCounselingPreferenceQuery } from "__generated__/types";
import { useOrder } from "services/orders/OrderGuard";

export const useCounselingPreference = () => {
  const order = useOrder();
  const { data, loading } = useCounselingPreferenceQuery({
    variables: { orderId: order.id! },
    skip: !order.id,
    fetchPolicy: "cache-and-network",
  });
  return { data, loading };
};
