import { ReactNode } from "react";
import { SnackbarProvider } from "notistack";
import { MessageEnqueuer } from "./MessageEnqueuer";

interface NotificationProps {
  children: ReactNode;
  max?: number;
}

/**
 * Service which provides support for notification popups displayed to the user
 * @param props
 * @returns JSX.Element
 */
export function Notification(props: NotificationProps) {
  const { children, max = 4 } = props;
  return (
    <SnackbarProvider maxSnack={max}>
      <MessageEnqueuer>{children}</MessageEnqueuer>
    </SnackbarProvider>
  );
}
