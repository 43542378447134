import styled from "@emotion/styled";
import { ReactNode } from "react";

const Container = styled.div({
  display: "grid",
  width: "60%",

  paddingRight: "0.5rem",
  paddingLeft: "0.5rem",
  marginRight: "auto",
  marginLeft: "auto",
  maxWidth: 1440,

  //Breakpoint - XLarge @ 1920px
  "@media (min-width: 1920px)": {
    paddingRight: "2rem",
    paddingLeft: "2rem",
  },

  //Breakpoint - Large @ 1440px
  "@media (max-width: 1440px)": {
    paddingRight: "2rem",
    paddingLeft: "2rem",
  },

  //Breakpoint - Medium @ 768px
  "@media (max-width: 768px)": {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    width: "80%",
  },

  //Breakpoint - Small @ 375px
  "@media (max-width: 375px)": {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    width: "100%",
  },
});

interface CenteredProps {
  children?: ReactNode;
}

export function Centered({ children }: CenteredProps) {
  return <Container>{children}</Container>;
}
