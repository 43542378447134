import type { RankInfo } from "./rankInfoMap";

export const createKey = (branch: string, rank: string) =>
  `${branch}${rank}`.toLowerCase().replace(/[^a-z]/g, "");
export function addRank(
  branchRankToImageMap: { [x: string]: string },
  { service, rank, img }: RankInfo
) {
  branchRankToImageMap[createKey(service, rank)] = img;
  return branchRankToImageMap;
}
