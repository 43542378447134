import classnames from "classnames";
import { FormattedMessage, useIntl } from "services";
import { Insignia } from "./Insignia";
import { Customer, Entitlement } from "models";
import { formatNumber } from "tools";
import scss from "./UserDetailsCard.module.scss";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

interface UserDetailsCardProps {
  customer: Customer;
  entitlement: Entitlement;
}

export function UserDetailsCard({
  customer,
  entitlement,
}: UserDetailsCardProps) {
  const { fullName, weightUnits } = customer;
  const { payGrade, rank, branch, rankAbbreviation } = entitlement;

  const { formatMessage } = useIntl();

  return (
    <div
      className={classnames(scss.userDetailsCard)}
      tabIndex={0}
      aria-label={formatMessage({ id: "ariaLabels.common.userDetailsCard" })}
      data-dd-privacy="mask"
    >
      <div className={classnames(scss.insignia)}>
        <Insignia branch={branch as string} rank={rank as string} />
      </div>

      <div className={classnames(scss.info)}>
        <Box>
          <Typography
            variant="xsHeading"
            aria-label={`${rank} ${payGrade}`}
            color="#666666"
          >
            {rankAbbreviation ? `${rankAbbreviation} (${payGrade})` : payGrade}
          </Typography>
        </Box>

        <Typography variant="lHeading" aria-label="Customer Name">
          {fullName}
        </Typography>
        {entitlement && (
          <Box>
            <Typography variant="xsBody" aria-label="Entitlements">
              <FormattedMessage
                id="personalInfo.entitlements"
                values={{
                  amount: formatNumber(entitlement.totalWeight),
                  measure: weightUnits,
                }}
              />
            </Typography>
          </Box>
        )}
      </div>
    </div>
  );
}
