import { ReactNode } from "react";
import { useSnackbar } from "notistack";
import { NotificationContext } from "./NotificationContext";

export function MessageEnqueuer({ children }: { children: ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <NotificationContext.Provider value={{ notify: enqueueSnackbar }}>
      {children}
    </NotificationContext.Provider>
  );
}
