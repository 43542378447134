import { ReactNode } from "react";
import { useToggle } from "react-use";
import classnames from "classnames";
import scss from "./HighlightUnbackedData.module.scss";

type ContainerOpts = { children?: ReactNode; block?: boolean };

export function HighlightUnbackedData(props: ContainerOpts) {
  const { children, block = false } = props;
  const [highlightUnbackedData, toggleHighlightUnbackedData] = useToggle(false);

  // useEffect(toggleHighlightUnbackedData, [router.pathname]);

  return highlightUnbackedData ? (
    block ? (
      <div className={classnames(scss.unbacked)}>{children}</div>
    ) : (
      <span className={classnames(scss.unbacked)}>{children}</span>
    )
  ) : (
    <>{children}</>
  );
}
