import { OrderAddress } from "models";
import { Form, Formik } from "formik";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "../../../services";
import { AddressFields } from "../AddressFields/AddressFields";
import { Button } from "../ui";
import { validateAddress } from "tools";
import { AddressFormData } from "types";
import { Box } from "@mui/system";

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

interface EditAddressFormProps {
  address: OrderAddress;
  saveButton?: boolean;
  onSubmit: (values: AddressFormData) => void;
}

export function EditAddressForm({
  address,
  onSubmit,
  saveButton = true,
}: EditAddressFormProps) {
  const { formatMessage } = useIntl();
  const addressAttributes = {
    street1: "",
    street2: "",
    locality: "",
    region: "",
    postalCode: "",
    ...address.attributes,
  };

  return (
    <Formik
      initialValues={{ ...addressAttributes }}
      onSubmit={onSubmit}
      validate={validateAddress}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <FormContainer>
            <Box mb="24px">
              <AddressFields />
            </Box>
            {saveButton && (
              <Button
                type="submit"
                variant={"primary"}
                size={"block"}
                ariaLabel={formatMessage({ id: "ariaLabels.save" })}
                disabled={!isValid}
                isSubmitting={isSubmitting}
              >
                <FormattedMessage id={"actions.save"} />
              </Button>
            )}
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
}
