import { HTMLAttributes } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";

interface NavPillProps extends HTMLAttributes<HTMLDivElement> {
  status: string;
  title?: string;
  className?: string;
  ariaLabel: string;
}

/**
 * PageContent component which adds margin, padding, and a max-width to its content.
 *
 * It also can animate in the components
 * @param props
 * @returns
 */
export function NavPill({
  status,
  title,
  className,
  ariaLabel,
  ...rest
}: NavPillProps) {
  const background = (status: string) => {
    switch (status) {
      case "complete":
        return "#62ce91";
      case "active":
        return "#f9cb24";
      default:
      case "inactive":
        return "#e5e5e5";
    }
  };

  const Container = styled.div({
    height: "0.75rem",
    borderRadius: "1rem",
    background: background(status),
  });

  return (
    <Container
      {...rest}
      aria-label={ariaLabel}
      className={classnames(className)}
    ></Container>
  );
}
