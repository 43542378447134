import { rankInfoMap } from "./rankInfoMap";
import { createKey } from "./addRank";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { branchFallback } from "./branch-fallback";

interface InsigniaProps {
  rank: string;
  branch: string;
}

export function Insignia({ rank, branch }: InsigniaProps) {
  const rankString = createKey(branch, rank);
  const img = rankInfoMap[rankString];
  useEffect(() => {
    if (!img) {
      datadogRum.addError("Missing Insignia", {
        rank,
        branch,
        key: rankString,
      });
    }
  }, [img]);
  const alt = `${branch} ${rank} Insignia`;
  return img ? (
    <img src={img} alt={alt} aria-label={alt} />
  ) : (
    <img src={branchFallback(branch)} alt={`${branch} seal`} />
  );
}
