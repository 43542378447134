"use client";
import { ReactNode } from "react";
import { useSpring, config } from "@react-spring/web";

import { Container } from "./Container";
import { Card } from "./Card";
import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";

export interface ErrorDisplayProps {
  children?: ReactNode;
}

/**
 * Component to display errors caught by an error boundary
 * @param props
 * @returns
 */
export function ErrorDisplay({ children }: ErrorDisplayProps) {
  const theme = useTheme();
  /*
  We used to have the error card animated but it appears to be broken
  with an update to next js app folder. Removing the animation for now
  and we can revisit later if there is a need.
  const spring = useSpring({
    from: { opacity: 0, transform: "translate3d(0,20px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
    config: (prop) => ({ ...config.gentle, clamp: prop === "opacity" }),
    reset: true,
  });
  */
  let childrenOrError;
  if (!children) {
    childrenOrError = (
      <Typography color={theme.palette.error.main}>An error occured</Typography>
    );
  } else {
    childrenOrError = children;
  }

  return (
    <Container>
      <Card>{childrenOrError}</Card>
    </Container>
  );
}
