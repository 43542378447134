import styled from "@emotion/styled";
import { ReactNode } from "react";

const Container = styled.div({
  display: "grid",
  width: "100%",
});

const InnerContainer = styled.div({
  display: "block",
  maxWidth: "640px",
  width: "100%",
});

interface LeftColumnProps {
  children?: ReactNode;
}

export function LeftColumn({ children }: LeftColumnProps) {
  return (
    <Container>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
}
