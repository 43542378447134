import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Waypoint } from "react-waypoint";

interface ISectionNavContext {
  /**
   * The id of the current section
   */
  currentSection: String;
  /**
   * Sets the current section. The locked param makes it so
   * further calls to this are ignored for a short period of time (500ms).
   * This helps us prevent the waypoint/window scroll from
   * changing the section when we navigate directly using the links.
   * @param section
   * @param lock
   */
  goToSection(section: string, lock?: boolean): void;
}

export const SectionNavContext = createContext<ISectionNavContext>({
  currentSection: "",
  goToSection: () => null,
});

/**
 * The context provider component which should wrap the scrollable area and menu
 * @param param0
 * @returns
 */
export const SectionNavProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentSection, setCurrentSection] = useState<string>("none");
  const [locked, setLocked] = useState(false);

  const goToSection = (section: string, lock?: boolean) => {
    if (!locked) {
      setCurrentSection(section);
      if (lock) {
        setLocked(true);
      }
    }
  };

  useEffect(() => {
    if (locked) {
      const timeout = setTimeout(() => {
        setLocked(false);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [locked]);

  const context: ISectionNavContext = {
    currentSection,
    goToSection: goToSection,
  };

  return (
    <SectionNavContext.Provider value={context}>
      {children}
    </SectionNavContext.Provider>
  );
};

/**
 * Context hook
 */
export const useSectionNav = () => useContext(SectionNavContext);

/**
 * Insert this component in each section to register it to the
 * section navigator
 * @returns React component
 */
export const SectionWaypoint: React.FC<{ section: string }> = ({ section }) => {
  const { goToSection } = useSectionNav();

  const handleLeave = ({
    previousPosition,
    currentPosition,
  }: Waypoint.CallbackArgs) => {
    // Only activate if we're scrolling down
    if (currentPosition === "above" && previousPosition === "inside") {
      goToSection(section);
    }
  };

  const handleEnter = ({
    previousPosition,
    currentPosition,
  }: Waypoint.CallbackArgs) => {
    // Only activate id we're scrolling UP
    if (currentPosition === "inside" && previousPosition === "above") {
      goToSection(section);
    }
  };

  return (
    <Waypoint onLeave={handleLeave} onEnter={handleEnter} topOffset="10px" />
  );
};
