import { OrderAddress } from "models";
import classnames from "classnames";
import scss from "./AddressTable.module.scss";
import { Box } from "@mui/system";

interface AddressRowProps {
  name: string;
  address: OrderAddress;
}

const AddressRow: React.FC<AddressRowProps> = ({ address, name }) => {
  return (
    <tr>
      <th scope="row">{`${name}:`}</th>
      <td>
        <Box pb="16px" data-dd-privacy="mask">
          {address.street1}
          <br />
          {address.street2 ? (
            <>
              {address.street2}
              <br />
            </>
          ) : null}
          {address.locality}, {address.region} {address.postalCode}
        </Box>
      </td>
    </tr>
  );
};

interface AddressTableProps {
  origin: OrderAddress;
  destination: OrderAddress;
}

export function OriginAndDestinationCard({
  origin,
  destination,
}: AddressTableProps) {
  return (
    <table className={classnames(scss.addressTable)}>
      <tbody>
        <AddressRow name="From" address={origin} />
        <AddressRow name="To" address={destination} />
      </tbody>
    </table>
  );
}
