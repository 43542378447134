import styled from "@emotion/styled";
import { animated } from "@react-spring/web";
import { Container as MuiContainer } from "@mui/material";

/**
 * @deprecated Use `PageWrapper`
 */
export const Container = animated(styled(MuiContainer)<{
  showtopoffset?: string;
}>`
  display: flex;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  height: 100%;
  padding-top: ${({ showtopoffset }) =>
    showtopoffset === "true" ? "1.5rem" : "0"};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
`) as any;
