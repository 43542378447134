import { Field, FieldProps } from "formik";
import { useIntl } from "../../../services";
import { Input, Select } from "components/common/ui";
import { STATE_OPTIONS } from "constant/stateOptions";
import { Grid, Box } from "@mui/material";

const formattedStateOptions = STATE_OPTIONS.map(([abbr, name]) => ({
  title: name,
  value: abbr,
}));

interface Props {
  /**
   * Pass to prefix all the fields with `{groupName}.street1`
   */
  groupName?: string;
}

export function AddressFields({ groupName }: Props) {
  const { formatMessage } = useIntl();
  const fieldPrefix = groupName ? `${groupName}.` : "";

  return (
    <div aria-label={formatMessage({ id: "ariaLabels.common.addressFields" })}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columns={4} columnSpacing="16px" rowSpacing="16px">
          <Grid xs={4} item>
            <Field type={"text"} name={`${fieldPrefix}street1`}>
              {({ field, meta }: FieldProps) => (
                <Input
                  {...field}
                  label="Street"
                  error={meta.touched && meta.error !== undefined}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid xs={4} item>
            <Field type={"text"} name={`${fieldPrefix}street2`}>
              {({ field, meta }: FieldProps) => (
                <Input
                  {...field}
                  label="Apt, suite, unit, floor, etc"
                  error={meta.touched && meta.error !== undefined}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid xs={4} item>
            <Field type={"text"} name={`${fieldPrefix}locality`}>
              {({ field, meta }: FieldProps) => (
                <Input
                  {...field}
                  label="City"
                  error={meta.touched && meta.error !== undefined}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid xs={4} sm={2} item>
            <Field type={"text"} name={`${fieldPrefix}region`}>
              {({ field, meta }: FieldProps) => (
                <Select
                  {...field}
                  label="State"
                  error={meta.touched && meta.error !== undefined}
                  options={formattedStateOptions}
                  name={field.name}
                ></Select>
              )}
            </Field>
          </Grid>
          <Grid xs={4} sm={2} item>
            <Field type={"text"} name={`${fieldPrefix}postalCode`}>
              {({ field, meta }: FieldProps) => (
                <Input
                  {...field}
                  label="Zip code"
                  error={meta.touched && meta.error !== undefined}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
