import { ReactNode } from "react";
import { useSpring, config } from "@react-spring/web";
import { useKey } from "react-use";
import { Overlay } from "./Overlay";

export type OverlayOptions = {
  onclose: () => void;
};

interface OverlayedProps {
  children?: ReactNode;
  opts: OverlayOptions;
}

function isEscapeKey(event: KeyboardEvent) {
  return event.key === "Escape";
}

export function Overlayed({ children, opts }: OverlayedProps) {
  function closeOverlay() {
    opts.onclose();
  }

  useKey(isEscapeKey, closeOverlay, { event: "keyup" });

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: (prop) => ({ ...config.stiff, clamp: prop === "opacity" }),
    reset: true,
  });

  return (
    <div>
      {children}
      <Overlay onClick={closeOverlay} style={spring} />
    </div>
  );
}
