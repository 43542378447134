import { formatNumber } from "tools";
import { FormattedMessage, useIntl } from "services";
import { Card, FootnoteMarker, Input } from "../ui";
import homeIcon from "./assets/home.svg";
import styled from "@emotion/styled";
import { Customer, Entitlement } from "models";
import { Box, Divider as MuiDivider, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";
import { usePpmCostCalculationsLazyQuery } from "__generated__/types";
import { useOrder } from "store";
import { formatPriceUsd } from "components/common/layouts/InventoryPageLayout/components/ClaimsDisplay/ClaimsList/moneyConversions";
import { useNotify } from "services/notification";
import { useCounselingPreference } from "hooks/useCounselingPreference";
import { theme } from "styles";

const ContainerLineItem = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ContentHeader = (props: { children: ReactNode }) => (
  <Typography variant="sHeading" {...props} />
);

const EntitlementValue = (props: { children: ReactNode }) => (
  <Typography
    variant="lHeading"
    color="#0069a0"
    textAlign={"right"}
    {...props}
  />
);

const Divider = styled(MuiDivider)`
  margin-bottom: ${() => `1.25rem`};
  padding-top: ${() => `1.25rem`};
  border-color: ${() => `#b3b3b3`};
`;

interface EntitlementsCardProps {
  customer: Customer;
  entitlement: Entitlement;
  calculateIncentiveEstimate?: boolean;
}

const PPMCostCalculation = () => {
  const { formatMessage } = useIntl();
  const { id: orderId } = useOrder();
  const notify = useNotify();

  const [weight, setWeight] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [gcc, setGcc] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [incentive, setIncentive] = useState(0);

  const [fetchData] = usePpmCostCalculationsLazyQuery();

  const validateWeight = (weight: number) => {
    if (weight <= 0) {
      return "Weight must be a positive number.";
    }
    if (!Number.isInteger(weight)) {
      return "Weight must be a whole number.";
    }
    if (weight > 1000000) {
      return "Weight must be less than one million.";
    }
    return null;
  };

  useDebounce(
    async () => {
      setError(null);

      if (!weight || weight === 0) {
        setGcc(0);
        setAdvance(0);
        setIncentive(0);
        return;
      }

      const validationError = validateWeight(weight);
      if (validationError) {
        setError(validationError);
        setGcc(0);
        setAdvance(0);
        setIncentive(0);
        return;
      }

      const { data, error } = await fetchData({
        variables: {
          orderId: orderId || "",
          weight: weight > 500 ? weight : 500,
        },
      });

      if (error) {
        notify("Failed to calculate PPM cost", {
          variant: "error",
        });
        return;
      }

      setGcc(data?.ppmCostCalculations?.gcc || 0);
      setAdvance(
        data?.ppmCostCalculations?.totalAdvanceOperatingAllowance || 0
      );
      setIncentive(data?.ppmCostCalculations?.totalIncentive || 0);
    },
    1000,
    [weight]
  );

  return (
    <>
      <Box mb="16px">
        <FormattedMessage id="entitlements.entitlementsCard.incentiveEstimate" />
      </Box>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.weightInput" />
        </ContentHeader>
        <Box>
          <Input
            type="number"
            value={weight}
            placeholder={formatMessage({
              id: "entitlements.entitlementsCard.inputPlaceholder",
            })}
            inputProps={{
              style: {
                padding: "2.5px 5px",
              },
              min: "0",
            }}
            onChange={({ currentTarget }) => {
              setWeight(Number(currentTarget.value));
            }}
            error={Boolean(error)}
            helperText={error}
            FormHelperTextProps={{
              sx: { fontSize: theme.typography.xxsBody.fontSize },
            }}
          />
        </Box>
      </ContainerLineItem>
      <EntitlementsCosts gcc={gcc} advance={advance} incentive={incentive} />
    </>
  );
};

function EntitlementsCosts({
  gcc = 0,
  advance = 0,
  incentive = 0,
}: {
  gcc?: number;
  advance?: number;
  incentive?: number;
}) {
  return (
    <>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.totalGovernment" />
        </ContentHeader>
        <EntitlementValue>{formatPriceUsd(gcc)}</EntitlementValue>
      </ContainerLineItem>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.totalAdvance" />
        </ContentHeader>
        <EntitlementValue>{formatPriceUsd(advance)}</EntitlementValue>
      </ContainerLineItem>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.totalIncentive" />
        </ContentHeader>
        <EntitlementValue>{formatPriceUsd(incentive)}</EntitlementValue>
      </ContainerLineItem>
      <ContainerLineItem>
        <ContentHeader>
          <FormattedMessage id="entitlements.entitlementsCard.commutedRate" />
        </ContentHeader>
        <EntitlementValue>$0</EntitlementValue>
      </ContainerLineItem>
    </>
  );
}

export function EntitlementsCard({
  customer,
  entitlement,
  calculateIncentiveEstimate,
}: EntitlementsCardProps) {
  const { formatMessage } = useIntl();
  const { data: { counselingPreference } = {} } = useCounselingPreference();
  const hasDependents = entitlement.dependentsAuthorized;
  const hasGunSafe = counselingPreference && counselingPreference.gunSafe;

  return (
    <Card
      title={<FormattedMessage id="entitlements.entitlementsCard.title" />}
      headerIcon={<img src={homeIcon.src} />}
      aria-label={formatMessage({ id: "ariaLabels.entitlementDetails" })}
      variant="large"
    >
      <Box
        mb="16px"
        aria-label={formatMessage({ id: "ariaLabels.summaryText" })}
      >
        <Typography variant="mBody">
          <FormattedMessage
            id="entitlements.entitlementsCard.message"
            values={{
              dependencyStatus: (
                <strong>
                  {hasDependents ? (
                    <FormattedMessage id="entitlements.entitlementsCard.withDependents" />
                  ) : (
                    <FormattedMessage id="entitlements.entitlementsCard.withoutDependents" />
                  )}
                </strong>
              ),
              paygrade: (
                <strong>
                  {entitlement.payGrade}{" "}
                  <FormattedMessage id="entitlements.entitlementsCard.paygrade" />
                </strong>
              ),
              moveType: (
                <strong>
                  <FormattedMessage id="entitlements.entitlementsCard.conusMove" />
                </strong>
              ),
            }}
          />
        </Typography>
      </Box>
      <div aria-label={formatMessage({ id: "ariaLabels.householdGoods" })}>
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.householdGoods" />
          </ContentHeader>
          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.householdGoodsAmount",
            })}
          >
            {formatNumber(entitlement?.totalWeight)} {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
      </div>
      {/* The Unaccompanied Baggage weight will be hardcoded to 0 lbs for go live */}
      <div
        aria-label={formatMessage({ id: "ariaLabels.unaccompaniedBaggage" })}
      >
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.unaccompaniedBaggage" />
          </ContentHeader>
          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.unaccompaniedBaggageAmount",
            })}
          >
            0 {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
      </div>
      <div aria-label={formatMessage({ id: "ariaLabels.workEntitlements" })}>
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.memberProGear" />
            <FootnoteMarker />
          </ContentHeader>

          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.workEntitlements.personal",
            })}
          >
            {entitlement?.proGearWeight > 0 ? "+" : ""}
            {formatNumber(entitlement?.proGearWeight)} {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
        <ContainerLineItem>
          <ContentHeader>
            <FormattedMessage id="entitlements.entitlementsCard.spouseProGear" />
            <FootnoteMarker />
          </ContentHeader>

          <EntitlementValue
            aria-label={formatMessage({
              id: "ariaLabels.workEntitlements.spouse",
            })}
          >
            {entitlement?.proGearWeightSpouse > 0 ? "+" : ""}
            {formatNumber(entitlement?.proGearWeightSpouse)}{" "}
            {customer.weightUnits}
          </EntitlementValue>
        </ContainerLineItem>
        {hasGunSafe ? (
          <ContainerLineItem data-testid="gunSafeRow">
            <ContentHeader>
              <FormattedMessage id="entitlements.entitlementsCard.emptyGunSafe" />
              <FootnoteMarker />
              <FootnoteMarker />
            </ContentHeader>

            <EntitlementValue
              aria-label={formatMessage({
                id: "ariaLabels.workEntitlements.safe",
              })}
            >
              +{formatNumber(entitlement?.gunSafeWeight)} {customer.weightUnits}
            </EntitlementValue>
          </ContainerLineItem>
        ) : null}
      </div>
      <Divider />
      {calculateIncentiveEstimate && <PPMCostCalculation />}
      {/* The 3 PPM fields and GSA Rates are hardcoded to $0 for go live */}
      <Box sx={{ marginTop: "24px" }}>
        <Typography variant="xsBody">
          <FootnoteMarker />
          <FormattedMessage id="entitlements.footnote.pbp" />
        </Typography>
      </Box>
      {hasGunSafe ? (
        <Box sx={{ marginTop: "8px" }}>
          <Typography variant="xsBody">
            <FootnoteMarker />
            <FootnoteMarker />
            <FormattedMessage id="entitlements.footnote.gunSafe" />
          </Typography>
        </Box>
      ) : null}
    </Card>
  );
}
