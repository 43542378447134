import { addRank } from "./addRank";

/**
 * These values represent the branch names as they appear
 * in the milmove injestion, which end up being populated
 * in the `entitlement.branch` field in Depot.
 */
export const BRANCH_KEYS = {
  ARMY: "ARMY",
  MARINES: "MARINES",
  NAVY: "NAVY",
  COAST_GUARD: "COAST_GUARD",
  AIR_FORCE: "AIR_FORCE",
  SPACE_FORCE: "SPACE_FORCE",
} as const;

export type BranchKeys = typeof BRANCH_KEYS;

export type RankInfo = {
  service: BranchKeys[keyof BranchKeys];
  img: string;
  paygrade: any;
  "raw-paygrade": string | null;
  "has-paygrade": boolean;
  rank: string;
  "abbrev-ctx": any;
  abbrev: string | null;
};

export const rankInfoArr: Array<RankInfo> = [
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E2-private-second-class.png",
    paygrade: "E-2",
    "raw-paygrade": "E-2",
    "has-paygrade": true,
    rank: "Private",
    "abbrev-ctx": "PV2",
    abbrev: "PV2",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E2-private-first-class.png",
    paygrade: "E-2",
    "raw-paygrade": "E-2",
    "has-paygrade": true,
    rank: "Private First Class",
    "abbrev-ctx": "PFC",
    abbrev: "PFC",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E2-seaman-apprentice.png",
    paygrade: "E-2",
    "raw-paygrade": "E-2",
    "has-paygrade": true,
    rank: "Seaman Apprentice",
    "abbrev-ctx": "SA",
    abbrev: "SA",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-2-airman.png",
    paygrade: "E-2",
    "raw-paygrade": "E-2",
    "has-paygrade": true,
    rank: "Airman",
    "abbrev-ctx": "Amn",
    abbrev: "Amn",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-2-specialist-2.png",
    paygrade: "E-2",
    "raw-paygrade": "E-2",
    "has-paygrade": true,
    rank: "Specialist 2",
    "abbrev-ctx": "Spc2",
    abbrev: "Spc2",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E2-seaman-apprentice.png",
    paygrade: "E-2",
    "raw-paygrade": "E-2",
    "has-paygrade": true,
    rank: "Seaman Apprentice",
    "abbrev-ctx": "SA",
    abbrev: "SA",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E3-private-first-class.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Private First Class",
    "abbrev-ctx": "PFC",
    abbrev: "PFC",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E3-lance-corporal.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Lance Corporal",
    "abbrev-ctx": "LCpl",
    abbrev: "LCpl",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E3-seaman.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Seaman",
    "abbrev-ctx": "SN",
    abbrev: "SN",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-3-airman-first-class.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Airman First Class",
    "abbrev-ctx": "A1C",
    abbrev: "A1C",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-3-specialist-3.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Specialist 3",
    "abbrev-ctx": "Spc3",
    abbrev: "Spc3",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E3-seaman.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Seaman",
    "abbrev-ctx": "SN",
    abbrev: "SN",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E4-corporal.png",
    paygrade: "E-4",
    "raw-paygrade": "E-4",
    "has-paygrade": true,
    rank: "Corporal",
    "abbrev-ctx": "CPL",
    abbrev: "CPL",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E4-specialist.png",
    paygrade: "E-4",
    "raw-paygrade": "E-4",
    "has-paygrade": true,
    rank: "Specialist",
    "abbrev-ctx": "SPC",
    abbrev: "SPC",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E4-corporal.png",
    paygrade: "E-4",
    "raw-paygrade": "E-4",
    "has-paygrade": true,
    rank: "Corporal",
    "abbrev-ctx": "Cpl",
    abbrev: "Cpl",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E4-petty-officer-third-class.png",
    paygrade: "E-4",
    "raw-paygrade": "E-4",
    "has-paygrade": true,
    rank: "Petty Officer Third Class",
    "abbrev-ctx": "PO3",
    abbrev: "PO3",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-4-senior-airman.png",
    paygrade: "E-4",
    "raw-paygrade": "E-4",
    "has-paygrade": true,
    rank: "Senior Airman",
    "abbrev-ctx": "SrA",
    abbrev: "SrA",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-4-specialist-4.png",
    paygrade: "E-3",
    "raw-paygrade": "E-3",
    "has-paygrade": true,
    rank: "Specialist 4",
    "abbrev-ctx": "Spc4",
    abbrev: "Spc4",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E4-petty-officer-third-class.png",
    paygrade: "E-4",
    "raw-paygrade": "E-4",
    "has-paygrade": true,
    rank: "Petty Officer Third Class",
    "abbrev-ctx": "PO3",
    abbrev: "PO3",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E5-sergeant.png",
    paygrade: "E-5",
    "raw-paygrade": "E-5",
    "has-paygrade": true,
    rank: "Sergeant",
    "abbrev-ctx": "SGT",
    abbrev: "SGT",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E5-sergeant.png",
    paygrade: "E-5",
    "raw-paygrade": "E-5",
    "has-paygrade": true,
    rank: "Sergeant",
    "abbrev-ctx": "Sgt",
    abbrev: "Sgt",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E5-petty-officer-second-class.png",
    paygrade: "E-5",
    "raw-paygrade": "E-5",
    "has-paygrade": true,
    rank: "Petty Officer Second Class",
    "abbrev-ctx": "PO2",
    abbrev: "PO2",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-5-staff-sergeant.png",
    paygrade: "E-5",
    "raw-paygrade": "E-5",
    "has-paygrade": true,
    rank: "Staff Sergeant",
    "abbrev-ctx": "SSgt",
    abbrev: "SSgt",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-5-sergeant.png",
    paygrade: "E-5",
    "raw-paygrade": "E-5",
    "has-paygrade": true,
    rank: "Sergeant",
    "abbrev-ctx": "Sgt",
    abbrev: "Sgt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E5-petty-officer-second-class.png",
    paygrade: "E-5",
    "raw-paygrade": "E-5",
    "has-paygrade": true,
    rank: "Petty Officer Second Class",
    "abbrev-ctx": "PO2",
    abbrev: "PO2",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E6-staff-sergeant.png",
    paygrade: "E-6",
    "raw-paygrade": "E-6",
    "has-paygrade": true,
    rank: "Staff Sergeant",
    "abbrev-ctx": "SSG",
    abbrev: "SSG",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E6-staff-sergeant.png",
    paygrade: "E-6",
    "raw-paygrade": "E-6",
    "has-paygrade": true,
    rank: "Staff Sergeant",
    "abbrev-ctx": "SSgt",
    abbrev: "SSgt",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E6-petty-officer-first-class.png",
    paygrade: "E-6",
    "raw-paygrade": "E-6",
    "has-paygrade": true,
    rank: "Petty Officer First Class",
    "abbrev-ctx": "PO1",
    abbrev: "PO1",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-6-technical-sergeant.png",
    paygrade: "E-6",
    "raw-paygrade": "E-6",
    "has-paygrade": true,
    rank: "Technical Sergeant",
    "abbrev-ctx": "TSgt",
    abbrev: "TSgt",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-6-technical-sergeant.png",
    paygrade: "E-6",
    "raw-paygrade": "E-6",
    "has-paygrade": true,
    rank: "Technical Sergeant",
    "abbrev-ctx": "TSgt",
    abbrev: "TSgt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E6-petty-officer-first-class.png",
    paygrade: "E-6",
    "raw-paygrade": "E-6",
    "has-paygrade": true,
    rank: "Petty Officer First Class",
    "abbrev-ctx": "PO1",
    abbrev: "PO1",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E7-sergeant-first-class.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "Sergeant First Class",
    "abbrev-ctx": "SFC",
    abbrev: "SFC",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E7-gunnery-sergeant.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "Gunnery Sergeant",
    "abbrev-ctx": "GySgt",
    abbrev: "GySgt",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E7-chief-petty-officer.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "Chief Petty Officer",
    "abbrev-ctx": "CPO",
    abbrev: "CPO",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-7-master-sergeant.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "Master Sergeant",
    "abbrev-ctx": "MSgt",
    abbrev: "MSgt",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-7-master-sergeant-First-Sergeant.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "First Sergeant",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.AIR_FORCE,
          "data-image":
            "/images/insignias/air-force/E-7-master-sergeant-First-Sergeant.png",
          "data-paygrade": "E-7",
          "data-title": "First Sergeant",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["First Sergeant"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-7 First Sergeant",
              src: "/images/insignias/air-force/E-7-master-sergeant-First-Sergeant.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-7-master-sergeant.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "Master Sergeant",
    "abbrev-ctx": "MSgt",
    abbrev: "MSgt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E7-chief-petty-officer.png",
    paygrade: "E-7",
    "raw-paygrade": "E-7",
    "has-paygrade": true,
    rank: "Chief Petty Officer",
    "abbrev-ctx": "CPO",
    abbrev: "CPO",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E8-master-sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "Master Sergeant",
    "abbrev-ctx": "MSG",
    abbrev: "MSG",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E8b-first-sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "First Sergeant",
    "abbrev-ctx": "1SG",
    abbrev: "1SG",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E8-master-sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "Master Sergeant",
    "abbrev-ctx": "MSgt",
    abbrev: "MSgt",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E8b first-sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "First Sergeant",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.MARINES,
          "data-image": "/images/insignias/marine-corps/E8b first-sergeant.png",
          "data-paygrade": "E-8",
          "data-title": "First Sergeant",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["First Sergeant"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-8 First Sergeant",
              src: "/images/insignias/marine-corps/E8b first-sergeant.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E8-senior-chief-petty-officer.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "Senior Chief Petty Officer",
    "abbrev-ctx": "SCPO",
    abbrev: "SCPO",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-8-senior-master-sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "Senior Master Sergeant",
    "abbrev-ctx": "SMSgt",
    abbrev: "SMSgt",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-8-senior-master-sergeant-First-Sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "First Sergeant",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.AIR_FORCE,
          "data-image":
            "/images/insignias/air-force/E-8-senior-master-sergeant-First-Sergeant.png",
          "data-paygrade": "E-8",
          "data-title": "First Sergeant",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["First Sergeant"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-8 First Sergeant",
              src: "/images/insignias/air-force/E-8-senior-master-sergeant-First-Sergeant.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-8-senior-master-sergeant.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "Senior Master Sergeant",
    "abbrev-ctx": "SMSgt",
    abbrev: "SMSgt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E8-senior-chief-petty-officer.png",
    paygrade: "E-8",
    "raw-paygrade": "E-8",
    "has-paygrade": true,
    rank: "Senior Chief Petty Officer",
    "abbrev-ctx": "SCPO",
    abbrev: "SCPO",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E9-sergeant-major.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Sergeant Major",
    "abbrev-ctx": "SGM",
    abbrev: "SGM",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E9b-command-sergeant-major.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Command Sergeant Major",
    "abbrev-ctx": "CSM",
    abbrev: "CSM",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E9-master-gunnery-sergeant.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Master Gunnery Sergeant",
    "abbrev-ctx": "MGySgt",
    abbrev: "MGySgt",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E9b-sergeant-major.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Sergeant Major",
    "abbrev-ctx": "SgtMaj",
    abbrev: "SgtMaj",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E9-master-chief-petty-officer.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Master Chief Petty Officer",
    "abbrev-ctx": "MCPO",
    abbrev: "MCPO",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E9b-Fleet-command-master-chief-petty-officer.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Fleet Command Master Chief Petty Officer",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.NAVY,
          "data-image":
            "/images/insignias/navy/E9b-Fleet-command-master-chief-petty-officer.png",
          "data-paygrade": "E-9",
          "data-title": "Fleet Command Master Chief Petty Officer",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["Fleet/Command Master Chief Petty Officer ** ***"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-9 Fleet Command Master Chief Petty Officer",
              src: "/images/insignias/navy/E9b-Fleet-command-master-chief-petty-officer.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-9-chief-master-sergeant.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Chief Master Sergeant",
    "abbrev-ctx": "CMSgt",
    abbrev: "CMSgt",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-9-chief-master-sergeant-First-Sergeant.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "First Sergeant",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.AIR_FORCE,
          "data-image":
            "/images/insignias/air-force/E-9-chief-master-sergeant-First-Sergeant.png",
          "data-paygrade": "E-9",
          "data-title": "First Sergeant",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["First Sergeant"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-9 First Sergeant",
              src: "/images/insignias/air-force/E-9-chief-master-sergeant-First-Sergeant.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-9-command-chief-master-sergeant.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Command Chief Master Sergeant",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.AIR_FORCE,
          "data-image":
            "/images/insignias/air-force/E-9-command-chief-master-sergeant.png",
          "data-paygrade": "E-9",
          "data-title": "Command Chief Master Sergeant",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["Command Chief Master Sergeant"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-9 Command Chief Master Sergeant",
              src: "/images/insignias/air-force/E-9-command-chief-master-sergeant.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-9-chief-master-sergeant.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Chief Master Sergeant",
    "abbrev-ctx": "CMSgt",
    abbrev: "CMSgt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E9-master-chief-petty-officer.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Master Chief Petty Officer",
    "abbrev-ctx": "MCPO",
    abbrev: "MCPO",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E9b-command-master-chief-petty-officer.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Command Master Chief Petty Officer",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Enlisted",
          "data-service": BRANCH_KEYS.COAST_GUARD,
          "data-image":
            "/images/insignias/coast-guard/E9b-command-master-chief-petty-officer.png",
          "data-paygrade": "E-9",
          "data-title": "Command Master Chief Petty Officer",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: ["Fleet/Command Master Chief Petty Officer ** ***"],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "E-9 Command Master Chief Petty Officer",
              src: "/images/insignias/coast-guard/E9b-command-master-chief-petty-officer.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/E9c-sergeant-major-of-the-army.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Sergeant Major of the Army",
    "abbrev-ctx": "SMA",
    abbrev: "SMA",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/E9c-sergeant-major-of-the-marine-corps.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Sergeant Major of the Marine Corps",
    "abbrev-ctx": "SgtMajMC",
    abbrev: "SgtMajMC",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/E9c-master-chief-petty-officer-of-the-navy.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Master Chief Petty Officer of the Navy",
    "abbrev-ctx": "MCPON",
    abbrev: "MCPON",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/E-9-chief-master-sergeant-of-the-air-force.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Chief Master Sergeant of the Air Force",
    "abbrev-ctx": "CMSAF",
    abbrev: "CMSAF",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/space-force/E-9-chief-master-sergeant-of-the-space-force.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Chief Master Sergeant of the Space Force",
    "abbrev-ctx": "CMSSF",
    abbrev: "CMSSF",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/E9c-master-chief-petty-officer-of-the-coast-guard.png",
    paygrade: "E-9",
    "raw-paygrade": "E-9",
    "has-paygrade": true,
    rank: "Master Chief Petty Officer of the Coast Guard",
    "abbrev-ctx": "MCPOCG",
    abbrev: "MCPOCG",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/W1-warrant-officer-1.png",
    paygrade: "W-1",
    "raw-paygrade": "W-1",
    "has-paygrade": true,
    rank: "Warrant Officer 1",
    "abbrev-ctx": "WO1",
    abbrev: "WO1",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/W1-warrant-officer-1.png",
    paygrade: "W-1",
    "raw-paygrade": "W-1",
    "has-paygrade": true,
    rank: "Warrant Officer 1",
    "abbrev-ctx": "WO",
    abbrev: "WO",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/officers/navywo1.png",
    paygrade: "USN",
    "raw-paygrade": "USN",
    "has-paygrade": true,
    rank: "Warrant Officer 1",
    "abbrev-ctx": "WO1",
    abbrev: "WO1",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/W2-chief-warrant-officer-2.png",
    paygrade: "W-2",
    "raw-paygrade": "W-2",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 2",
    "abbrev-ctx": "CW2",
    abbrev: "CW2",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/W2-chief-warrant-officer-2.png",
    paygrade: "W-2",
    "raw-paygrade": "W-2",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 2",
    "abbrev-ctx": "CWO2",
    abbrev: "CWO2",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/W2-chief-warrant-officer-2.png",
    paygrade: "W-2",
    "raw-paygrade": "W-2",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 2",
    "abbrev-ctx": "CWO2",
    abbrev: "CWO2",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/W-2-chief-warrant-officer-2.jpg",
    paygrade: "W-2",
    "raw-paygrade": "W-2",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 2",
    "abbrev-ctx": "CWO2",
    abbrev: "CWO2",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/W3-chief-warrant-officer-3.png",
    paygrade: "W-3",
    "raw-paygrade": "W-3",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 3",
    "abbrev-ctx": "CW3",
    abbrev: "CW3",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/W3-chief-warrant-officer-3.png",
    paygrade: "W-3",
    "raw-paygrade": "W-3",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 3",
    "abbrev-ctx": "CWO3",
    abbrev: "CWO3",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/W3-chief-warrant-officer-3.png",
    paygrade: "W-3",
    "raw-paygrade": "W-3",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 3",
    "abbrev-ctx": "CWO3",
    abbrev: "CWO3",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/W-3-chief-warrant-officer-3.jpg",
    paygrade: "W-3",
    "raw-paygrade": "W-3",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 3",
    "abbrev-ctx": "CWO3",
    abbrev: "CWO3",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/W4-chief-warrant-officer-4.png",
    paygrade: "W-4",
    "raw-paygrade": "W-4",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 4",
    "abbrev-ctx": "CW4",
    abbrev: "CW4",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/W4-chief-warrant-officer-4.png",
    paygrade: "W-4",
    "raw-paygrade": "W-4",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 4",
    "abbrev-ctx": "CWO4",
    abbrev: "CWO4",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/W4-chief-warrant-officer-4.png",
    paygrade: "W-4",
    "raw-paygrade": "W-4",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 4",
    "abbrev-ctx": "CWO4",
    abbrev: "CWO4",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/W-4-chief-warrant-officer-4.jpg",
    paygrade: "W-4",
    "raw-paygrade": "W-4",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 4",
    "abbrev-ctx": "CWO4",
    abbrev: "CWO4",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/W5-chief-warrant-officer-5.png",
    paygrade: "W-5",
    "raw-paygrade": "W-5",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 5",
    "abbrev-ctx": "CW5",
    abbrev: "CW5",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/W5-chief-warrant-officer-5.png",
    paygrade: "W-5",
    "raw-paygrade": "W-5",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 5",
    "abbrev-ctx": "CWO5",
    abbrev: "CWO5",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/W5-chief-warrant-officer-5.png",
    paygrade: "W-5",
    "raw-paygrade": "W-5",
    "has-paygrade": true,
    rank: "Chief Warrant Officer 5",
    "abbrev-ctx": "CWO5",
    abbrev: "CWO5",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O1-second-lieutenant.png",
    paygrade: "O-1",
    "raw-paygrade": "O-1",
    "has-paygrade": true,
    rank: "Second Lieutenant",
    "abbrev-ctx": "2LT",
    abbrev: "2LT",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O1-second-lieutenant.png",
    paygrade: "O-1",
    "raw-paygrade": "O-1",
    "has-paygrade": true,
    rank: "Second Lieutenant",
    "abbrev-ctx": "2ndLt",
    abbrev: "2ndLt",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O1-ensign.png",
    paygrade: "O-1",
    "raw-paygrade": "O-1 Ensign",
    "has-paygrade": true,
    rank: "Ensign",
    "abbrev-ctx": "ENS",
    abbrev: "ENS",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-1-second-lieutenant.png",
    paygrade: "O-1",
    "raw-paygrade": "O-1",
    "has-paygrade": true,
    rank: "Second Lieutenant",
    "abbrev-ctx": "2d Lt",
    abbrev: "2d Lt",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-1-second-lieutenant.png",
    paygrade: "O-1",
    "raw-paygrade": "O-1",
    "has-paygrade": true,
    rank: "Second Lieutenant",
    "abbrev-ctx": "2d Lt",
    abbrev: "2d Lt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O1-ensign.png",
    paygrade: "O-1",
    "raw-paygrade": "O-1 Ensign",
    "has-paygrade": true,
    rank: "Ensign",
    "abbrev-ctx": "ENS",
    abbrev: "ENS",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O2-first-lieutenant.png",
    paygrade: "O-2",
    "raw-paygrade": "O-2",
    "has-paygrade": true,
    rank: "First Lieutenant",
    "abbrev-ctx": "1LT",
    abbrev: "1LT",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O2-first-lieutenant.png",
    paygrade: "O-2",
    "raw-paygrade": "O-2",
    "has-paygrade": true,
    rank: "First Lieutenant",
    "abbrev-ctx": "1stLt",
    abbrev: "1stLt",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O2-lieutenant-junior-grade.png",
    paygrade: "O-2",
    "raw-paygrade": "O-2",
    "has-paygrade": true,
    rank: "Lieutenant Junior Grade",
    "abbrev-ctx": "LTJG",
    abbrev: "LTJG",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-2-first-lieutenant.png",
    paygrade: "O-2",
    "raw-paygrade": "O-2",
    "has-paygrade": true,
    rank: "First Lieutenant",
    "abbrev-ctx": "1st Lt",
    abbrev: "1st Lt",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-2-first-lieutenant.png",
    paygrade: "O-2",
    "raw-paygrade": "O-2",
    "has-paygrade": true,
    rank: "First Lieutenant",
    "abbrev-ctx": "1st Lt",
    abbrev: "1st Lt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O2-lieutenant-junior-grade.png",
    paygrade: "O-2",
    "raw-paygrade": "O-2",
    "has-paygrade": true,
    rank: "Lieutenant Junior Grade",
    "abbrev-ctx": "LTJG",
    abbrev: "LTJG",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O3-captain.png",
    paygrade: "O-3",
    "raw-paygrade": "O-3 Captain",
    "has-paygrade": true,
    rank: "Captain",
    "abbrev-ctx": "CPT",
    abbrev: "CPT",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O3-captain.png",
    paygrade: "O-3",
    "raw-paygrade": "O-3 Captain",
    "has-paygrade": true,
    rank: "Captain",
    "abbrev-ctx": "Capt",
    abbrev: "Capt",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O3-lieutenant.png",
    paygrade: "O-3",
    "raw-paygrade": "O-3",
    "has-paygrade": true,
    rank: "Lieutenant",
    "abbrev-ctx": "LT",
    abbrev: "LT",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-3-captain.png",
    paygrade: "O-3",
    "raw-paygrade": "O-3 Captain",
    "has-paygrade": true,
    rank: "Captain",
    "abbrev-ctx": "Capt",
    abbrev: "Capt",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-3-captain.png",
    paygrade: "O-3",
    "raw-paygrade": "O-3 Captain",
    "has-paygrade": true,
    rank: "Captain",
    "abbrev-ctx": "Capt",
    abbrev: "Capt",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O3-lieutenant.png",
    paygrade: "O-3",
    "raw-paygrade": "O-3",
    "has-paygrade": true,
    rank: "Lieutenant",
    "abbrev-ctx": "LT",
    abbrev: "LT",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O4-major.png",
    paygrade: "O-4",
    "raw-paygrade": "O-4 Major",
    "has-paygrade": true,
    rank: "Major",
    "abbrev-ctx": "MAJ",
    abbrev: "MAJ",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O4-major.png",
    paygrade: "O-4",
    "raw-paygrade": "O-4 Major",
    "has-paygrade": true,
    rank: "Major",
    "abbrev-ctx": "Maj",
    abbrev: "Maj",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O4-lieutenant-commander.png",
    paygrade: "O-4",
    "raw-paygrade": "O-4",
    "has-paygrade": true,
    rank: "Lieutenant Commander",
    "abbrev-ctx": "LCDR",
    abbrev: "LCDR",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-4-major.png",
    paygrade: "O-4",
    "raw-paygrade": "O-4 Major",
    "has-paygrade": true,
    rank: "Major",
    "abbrev-ctx": "Maj",
    abbrev: "Maj",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-4-major.png",
    paygrade: "O-4",
    "raw-paygrade": "O-4 Major",
    "has-paygrade": true,
    rank: "Major",
    "abbrev-ctx": "Maj",
    abbrev: "Maj",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O4-lieutenant-commander.png",
    paygrade: "O-4",
    "raw-paygrade": "O-4",
    "has-paygrade": true,
    rank: "Lieutenant Commander",
    "abbrev-ctx": "LCDR",
    abbrev: "LCDR",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O5-lieutenant-colonel.png",
    paygrade: "O-5",
    "raw-paygrade": "O-5",
    "has-paygrade": true,
    rank: "Lieutenant Colonel",
    "abbrev-ctx": "LTC",
    abbrev: "LTC",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O5-lieutenant-colonel.png",
    paygrade: "O-5",
    "raw-paygrade": "O-5",
    "has-paygrade": true,
    rank: "Lieutenant Colonel",
    "abbrev-ctx": "LtCol",
    abbrev: "LtCol",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O5-commander.png",
    paygrade: "O-5",
    "raw-paygrade": "O-5 Commander",
    "has-paygrade": true,
    rank: "Commander",
    "abbrev-ctx": "CDR",
    abbrev: "CDR",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-5-lieutenant-colonel.png",
    paygrade: "O-5",
    "raw-paygrade": "O-5",
    "has-paygrade": true,
    rank: "Lieutenant Colonel",
    "abbrev-ctx": "Lt Col",
    abbrev: "Lt Col",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-5-lieutenant-colonel.png",
    paygrade: "O-5",
    "raw-paygrade": "O-5",
    "has-paygrade": true,
    rank: "Lieutenant Colonel",
    "abbrev-ctx": "Lt Col",
    abbrev: "Lt Col",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O5-commander.png",
    paygrade: "O-5",
    "raw-paygrade": "O-5",
    "has-paygrade": true,
    rank: "Commander",
    "abbrev-ctx": "CDR",
    abbrev: "CDR",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O6-colonel.png",
    paygrade: "O-6",
    "raw-paygrade": "O-6 Colonel",
    "has-paygrade": true,
    rank: "Colonel",
    "abbrev-ctx": "COL",
    abbrev: "COL",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O6-colonel.png",
    paygrade: "O-6",
    "raw-paygrade": "O-6 Colonel",
    "has-paygrade": true,
    rank: "Colonel",
    "abbrev-ctx": "Col",
    abbrev: "Col",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O6-captain.png",
    paygrade: "O-6",
    "raw-paygrade": "O-6 Captain",
    "has-paygrade": true,
    rank: "Captain",
    "abbrev-ctx": "CAPT",
    abbrev: "CAPT",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-6-colonel.png",
    paygrade: "O-6",
    "raw-paygrade": "O-6 Colonel",
    "has-paygrade": true,
    rank: "Colonel",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.AIR_FORCE,
          "data-image": "/images/insignias/air-force/O-6-colonel.png",
          "data-paygrade": "O-6 Colonel",
        },
        tag: "a",
        content: [
          {
            type: "element",
            attrs: {
              alt: "O-6",
              "data-title": "Colonel",
              src: "/images/insignias/air-force/O-6-colonel.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-6-colonel.png",
    paygrade: "O-6",
    "raw-paygrade": "O-6 Colonel",
    "has-paygrade": true,
    rank: "Colonel",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.SPACE_FORCE,
          "data-image": "/images/insignias/air-force/O-6-colonel.png",
          "data-paygrade": "O-6 Colonel",
        },
        tag: "a",
        content: [
          {
            type: "element",
            attrs: {
              alt: "O-6",
              "data-title": "Colonel",
              src: "/images/insignias/air-force/O-6-colonel.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O6-captain.png",
    paygrade: "O-6",
    "raw-paygrade": "O-6 Captain",
    "has-paygrade": true,
    rank: "Captain",
    "abbrev-ctx": "CAPT",
    abbrev: "CAPT",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O7-Brigadier-General.png",
    paygrade: "O-7",
    "raw-paygrade": "O-7",
    "has-paygrade": true,
    rank: "Brigadier General",
    "abbrev-ctx": "BG",
    abbrev: "BG",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O7-Brigadier-General.png",
    paygrade: "O-7",
    "raw-paygrade": "O-7",
    "has-paygrade": true,
    rank: "Brigadier General",
    "abbrev-ctx": "BGen",
    abbrev: "BGen",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O7-rear-admiral-lower-half.png",
    paygrade: "O-7",
    "raw-paygrade": "O-7",
    "has-paygrade": true,
    rank: "Rear Admiral Lower Half",
    "abbrev-ctx": "RDML",
    abbrev: "RDML",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-7-Brigadier-General.png",
    paygrade: "O-7",
    "raw-paygrade": "O-7",
    "has-paygrade": true,
    rank: "Brigadier General",
    "abbrev-ctx": "Brig Gen",
    abbrev: "Brig Gen",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-7-Brigadier-General.png",
    paygrade: "O-7",
    "raw-paygrade": "O-7",
    "has-paygrade": true,
    rank: "Brigadier General",
    "abbrev-ctx": "Brig Gen",
    abbrev: "Brig Gen",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O7-rear-admiral-lower-half.png",
    paygrade: "O-7",
    "raw-paygrade": "O-7",
    "has-paygrade": true,
    rank: "Rear Admiral Lower Half",
    "abbrev-ctx": {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.COAST_GUARD,
          "data-image":
            "/images/insignias/coast-guard/O7-rear-admiral-lower-half.png",
          "data-paygrade": "O-7",
          "data-title": "Rear Admiral Lower Half",
        },
        tag: "a",
        content: [
          {
            type: "element",
            attrs: {
              alt: "O-7 Rear Admiral Lower Half",
              src: "/images/insignias/coast-guard/O7-rear-admiral-lower-half.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    abbrev: null,
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O8-Major-General.png",
    paygrade: "O-8",
    "raw-paygrade": "O-8 Major General",
    "has-paygrade": true,
    rank: "Major",
    "abbrev-ctx": "MG",
    abbrev: "MG",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O8-Major-General.png",
    paygrade: "O-8",
    "raw-paygrade": "O-8",
    "has-paygrade": true,
    rank: "Major General",
    "abbrev-ctx": "MajGen",
    abbrev: "MajGen",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O8-rear-admiral.png",
    paygrade: "O-8",
    "raw-paygrade": "O-8 Rear Admiral",
    "has-paygrade": true,
    rank: "Rear",
    "abbrev-ctx": "RADM",
    abbrev: "RADM",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-8-Major-General.png",
    paygrade: "O-8",
    "raw-paygrade": "O-8",
    "has-paygrade": true,
    rank: "Major General",
    "abbrev-ctx": "Maj Gen",
    abbrev: "Maj Gen",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-8-Major-General.png",
    paygrade: "O-8",
    "raw-paygrade": "O-8",
    "has-paygrade": true,
    rank: "Major General",
    "abbrev-ctx": "Maj Gen",
    abbrev: "Maj Gen",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O8-rear-admiral.png",
    paygrade: "O-8",
    "raw-paygrade": "O-8",
    "has-paygrade": true,
    rank: "Rear Admiral",
    "abbrev-ctx": "RADM",
    abbrev: "RADM",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O9-Lieutenant-General.png",
    paygrade: "O-9",
    "raw-paygrade": "O-9",
    "has-paygrade": true,
    rank: "Lieutenant General",
    "abbrev-ctx": "LTG",
    abbrev: "LTG",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O9-Lieutenant-General.png",
    paygrade: "O-9",
    "raw-paygrade": "O-9",
    "has-paygrade": true,
    rank: "Lieutenant General",
    "abbrev-ctx": "LtGen",
    abbrev: "LtGen",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O9-vice-admiral.png",
    paygrade: "O-9",
    "raw-paygrade": "O-9 Vice Admiral",
    "has-paygrade": true,
    rank: "Vice",
    "abbrev-ctx": "VADM",
    abbrev: "VADM",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-9-Lieutenant-General.png",
    paygrade: "O-9",
    "raw-paygrade": "O-9",
    "has-paygrade": true,
    rank: "Lieutenant General",
    "abbrev-ctx": "Lt Gen",
    abbrev: "Lt Gen",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-9-Lieutenant-General.png",
    paygrade: "O-9",
    "raw-paygrade": "O-9",
    "has-paygrade": true,
    rank: "Lieutenant General",
    "abbrev-ctx": "Lt Gen",
    abbrev: "Lt Gen",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O9-vice-admiral.png",
    paygrade: "O-9",
    "raw-paygrade": "O-9",
    "has-paygrade": true,
    rank: "Vice Admiral",
    "abbrev-ctx": "VADM",
    abbrev: "VADM",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O10-General.png",
    paygrade: "O-10",
    "raw-paygrade": "O-10 General",
    "has-paygrade": true,
    rank: "General",
    "abbrev-ctx": "GEN",
    abbrev: "GEN",
  },
  {
    service: BRANCH_KEYS.MARINES,
    img: "/images/insignias/marine-corps/O10-General.png",
    paygrade: "O-10",
    "raw-paygrade": "O-10",
    "has-paygrade": true,
    rank: "General",
    "abbrev-ctx": "Gen",
    abbrev: "Gen",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O10-admiral.png",
    paygrade: "O-10",
    "raw-paygrade": "O-10",
    "has-paygrade": true,
    rank: "Admiral",
    "abbrev-ctx": "ADM",
    abbrev: "ADM",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/O-10-General.png",
    paygrade: "O-10",
    "raw-paygrade": "O-10",
    "has-paygrade": true,
    rank: "General",
    "abbrev-ctx": "Gen",
    abbrev: "Gen",
  },
  {
    service: BRANCH_KEYS.SPACE_FORCE,
    img: "/images/insignias/air-force/O-10-General.png",
    paygrade: "O-10",
    "raw-paygrade": "O-10",
    "has-paygrade": true,
    rank: "General",
    "abbrev-ctx": "Gen",
    abbrev: "Gen",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/coast-guard/O10-admiral.png",
    paygrade: "O-10",
    "raw-paygrade": "O-10",
    "has-paygrade": true,
    rank: "Admiral",
    "abbrev-ctx": "ADM",
    abbrev: "ADM",
  },
  {
    service: BRANCH_KEYS.ARMY,
    img: "/images/insignias/army/O11-General-of-the-Army.png",
    paygrade: {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.ARMY,
          "data-image": "/images/insignias/army/O11-General-of-the-Army.png",
          "data-title": "General of the Army",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: [
              "General of the Army ",
              {
                type: "element",
                attrs: null,
                tag: "span",
                content: ["(Reserved for wartime only)"],
              },
            ],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "O-11 General of the Army",
              src: "/images/insignias/army/O11-General-of-the-Army.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    "raw-paygrade": null,
    "has-paygrade": false,
    rank: "General of the Army",
    "abbrev-ctx": "Reserved for wartime only",
    abbrev: "Reserved for wartime only",
  },
  {
    service: BRANCH_KEYS.NAVY,
    img: "/images/insignias/navy/O11-Fleet-Admiral.png",
    paygrade: {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.NAVY,
          "data-image": "/images/insignias/navy/O11-Fleet-Admiral.png",
          "data-title": "O-11 Fleet Admiral",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: [
              "Fleet Admiral ",
              {
                type: "element",
                attrs: null,
                tag: "span",
                content: ["(Reserved for wartime only)"],
              },
            ],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "O-11 Fleet Admiral",
              src: "/images/insignias/navy/O11-Fleet-Admiral.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    "raw-paygrade": null,
    "has-paygrade": false,
    rank: "O-11 Fleet Admiral",
    "abbrev-ctx": "Reserved for wartime only",
    abbrev: "Reserved for wartime only",
  },
  {
    service: BRANCH_KEYS.AIR_FORCE,
    img: "/images/insignias/air-force/General-of-the-Air-Force.png",
    paygrade: {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.AIR_FORCE,
          "data-image":
            "/images/insignias/air-force/General-of-the-Air-Force.png",
          "data-title": "General of the Air Force",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: [
              "General of the Air Force ",
              {
                type: "element",
                attrs: null,
                tag: "span",
                content: ["(Reserved for wartime only)"],
              },
            ],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "General of the Air Force",
              src: "/images/insignias/air-force/General-of-the-Air-Force.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    "raw-paygrade": null,
    "has-paygrade": false,
    rank: "General of the Air Force",
    "abbrev-ctx": "Reserved for wartime only",
    abbrev: "Reserved for wartime only",
  },
  {
    service: BRANCH_KEYS.COAST_GUARD,
    img: "/images/insignias/navy/O11-Fleet-Admiral.png",
    paygrade: {
      MISSING: {
        type: "element",
        attrs: {
          class: "insignia-image-link",
          "data-toggle": "modal",
          "data-target": "#insigniaModal",
          "data-type": "Officer",
          "data-service": BRANCH_KEYS.COAST_GUARD,
          "data-image": "/images/insignias/navy/O11-Fleet-Admiral.png",
          "data-title": "Fleet Admiral",
        },
        tag: "a",
        content: [
          "\n                ",
          {
            type: "element",
            attrs: null,
            tag: "p",
            content: [
              "Fleet Admiral ",
              {
                type: "element",
                attrs: null,
                tag: "span",
                content: ["(Reserved for wartime only)"],
              },
            ],
          },
          "\n                ",
          {
            type: "element",
            attrs: {
              alt: "O-11 Fleet Admiral",
              src: "/images/insignias/navy/O11-Fleet-Admiral.png",
            },
            tag: "img",
            content: null,
          },
        ],
      },
    },
    "raw-paygrade": null,
    "has-paygrade": false,
    rank: "Fleet Admiral",
    "abbrev-ctx": "Reserved for wartime only",
    abbrev: "Reserved for wartime only",
  },
];

export const rankInfoMap = rankInfoArr.reduce(addRank, {});
