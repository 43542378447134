import styled from "@emotion/styled";
import { animated } from "@react-spring/web";

export const Overlay = animated(styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgb(1 49 74 / 85%);
  color: white;
`);
