export const supportedBranches = [
  "air-force",
  "army",
  "coast-guard",
  "marine-corps",
  "navy",
  "space-force",
];
export const dodSealUrl = "/images/insignias/dod-seal.png";

export const branchFallback = (branch?: string) => {
  let branchPath = branch?.toLowerCase()?.replace(/[\s_]/, "-");
  if (branchPath === "marines") {
    branchPath = "marine-corps";
  }

  if (!supportedBranches.includes(branchPath || "")) {
    return dodSealUrl;
  }

  return `/images/insignias/${branchPath}/seal.png`;
};
