import { ReactNode } from "react";
import styled from "@emotion/styled";
import classnames from "classnames";
import { InventoryItem } from "models";
import { OrderRoom } from "models";
import scss from "./InventoryDetails.module.scss";

interface InventoryDetailsProps {
  item: InventoryItem | null;
  room: OrderRoom | null;
  headerContent?: ReactNode;
}

const HeaderContent = styled.div`
  display: grid;
  place-items: center;
`;

export function InventoryDetailsCard(props: InventoryDetailsProps) {
  const { item, room, headerContent = null } = props;

  if (!item || !room) {
    return null;
  }

  return (
    <div aria-label="Inventory Details">
      <section className={classnames(scss.inventoryDetails)}>
        <header>
          <h3>{item.itemName}</h3>
          <HeaderContent>{headerContent}</HeaderContent>
        </header>
        <div className={classnames(scss.content)}>
          <div className={classnames(scss.imageContainer)}>
            {item.imageUrl !== "" && (
              <div className={classnames(scss.imageWrapper)}>
                <img
                  src={item.imageUrl}
                  className={classnames(scss.thumbnail)}
                />
              </div>
            )}
          </div>
          <div className={classnames(scss.topContentContainer)}>
            <ul>
              <li>
                <strong>Room:</strong>
                {room.roomName}
              </li>
              <li>
                <strong>Lot#:</strong>
                {item.lotNumber}
              </li>
              <li>
                <strong>Tag#:</strong>
                {item.tagNumber}
              </li>
            </ul>
          </div>
          <div className={classnames(scss.conditionsAtOrigin)}>
            <strong>Conditions at Origin:</strong>
            <p>{item.originConditions}</p>
          </div>
        </div>
      </section>
    </div>
  );
}
