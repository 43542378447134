import { InfoOutlined } from "@mui/icons-material";
import React from "react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

//---------------------
// TYPES
//---------------------

export type ThemeColor = "BLUE" | "GREEN" | "RED";

interface ColorBoxProps {
  themeColor: ThemeColor;
}

type InfoBoxProps = {
  title?: React.ReactNode;
  content: React.ReactNode;
  color?: ThemeColor;
  onClose?: () => any;
};

//---------------------
// UTILITY FUNCTIONS
//---------------------

const getColors = (
  themeColor: ThemeColor = "BLUE"
): { background: string; color: string; border: string } => {
  switch (themeColor) {
    case "GREEN":
      return {
        background: "#DEF0EB",
        color: "#115B42",
        border: "rgba(0, 152, 116, 0.12)",
      };

    case "RED":
      return {
        background: "#FBEAEA",
        color: "#AD1919",
        border: "rgba(234, 22, 22, 0.12)",
      };

    case "BLUE":
    default:
      return {
        background: "#E5F1FA",
        color: "#105990",
        border: "rgba(16, 89, 144, 0.12);",
      };
  }

  throw Error(`InfoBox: Missing approval status case for ${themeColor}`);
};

//---------------------
// STYLES
//---------------------

const InfoIcon = styled(InfoOutlined)({
  height: "1.25rem",
  width: "1.25rem",
});

const Inner = styled.div({
  paddingLeft: "0.75rem",
  position: "relative",
});

const Title = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  position: "relative",
  marginBottom: "4px",
});

const InfoBoxContainer = styled.div<ColorBoxProps>((props) => {
  const colors = getColors(props.themeColor);
  return {
    background: colors.background,
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${colors.border}`,
    borderRadius: "0.2rem",
    color: colors.color,
    position: "relative",
  };
});

const CloseButton = styled.button<ColorBoxProps>((props) => {
  const colors = getColors(props.themeColor);
  return {
    position: "absolute",
    background: "none",
    padding: 0,
    fontSize: "1.25rem",
    height: "1.5rem",
    width: "1.5rem",
    border: "none",
    color: colors.color,
    top: "-0.5rem",
    right: "-0.2rem",
    cursor: "pointer",
  };
});

//---------------------
// COMPONENT
//---------------------

/**
 * TODO: Integrate this compnent into the info box as shown in the
 * move tracker for change requests.
 * @param param0
 * @returns
 */

export function InfoBoxNew({
  title,
  content,
  color = "BLUE",
  onClose,
}: InfoBoxProps) {
  return (
    <InfoBoxContainer themeColor={color}>
      <InfoIcon>
        <InfoOutlined />
      </InfoIcon>
      <Inner>
        {title ? (
          <Title>
            <Typography variant="mHeading">{title}</Typography>
          </Title>
        ) : null}

        <Typography variant="sBody">{content}</Typography>
        {onClose ? (
          <CloseButton
            data-testid="info-box-close-button"
            onClick={onClose}
            themeColor={color}
          >
            <CloseIcon />
          </CloseButton>
        ) : null}
      </Inner>
    </InfoBoxContainer>
  );
}
