import { HTMLAttributes } from "react";
import { Container } from "./Container";

interface PageContent extends HTMLAttributes<HTMLDivElement> {
  animate?: string;
  reset?: boolean;
  showTopOffset?: boolean;
}

/**
 * @deprecated Use `PageWrapper` instead
 *
 * PageContent component which adds margin, padding, and a max-width to its content.
 * @param props
 * @returns
 */
export function PageContent(props: PageContent) {
  const { reset = true, showTopOffset = "true", ...rest } = props;
  return <Container showtopoffset={showTopOffset} component="main" {...rest} />;
}
