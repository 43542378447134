import { useContext } from "react";
import { NotificationContext } from "./NotificationContext";

/**
 * Returns callback that can be used to display a toast message to the user
 * @returns notify()
 */
export function useNotify() {
  const { notify } = useContext(NotificationContext);
  return notify;
}
